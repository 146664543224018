<template>
  <div class="productDescription">
    <div class="content">
      <div class="pdtImg">
        <img :src="image ? ipfs(image) : errorImg" @error.once="imgerror" />
      </div>
      <div class="buy">
        <h4>{{ name }}</h4>
        <div v-if="identify != 'held'">
          <span style="opacity: 0.5">{{ $t('nft.market.price') }}</span>
          <span style="color: #e1ab01">{{ checkPrice(price) }}KNT</span>
        </div>
        <!-- 市场的按钮 -->
        <button @click="link" v-if="!isLink && identify == 'market'">
          {{ $t('nft.description.linkPurse') }}
        </button>
        <button
          v-if="isLink && !isMyNft && identify == 'market'"
          @click="buynft"
        >
          {{ $t('nft.description.buy') }}
        </button>
        <button
          v-if="isLink && isMyNft && identify == 'market'"
          @click="cancel"
        >
          {{ $t('nft.personal.personalSale.cancelTheSale') }}
        </button>
        <!-- 项目发行的按钮 -->
        <button
          style="background: #df3636"
          v-if="identify == 'project'"
          @click="confirmNft"
        >
          {{ $t('nft.description.buy') }}
        </button>
        <!-- 拥有nft的按钮 -->
        <button v-if="identify == 'held'" @click="confirmMy">
          {{ $t('nft.personal.personalHeld.sell') }}
        </button>
      </div>
      <ul class="source">
        <li v-if="seller">
          <span>{{ $t('nft.description.owner') }}</span>
          <span>{{ formateAddress(seller) }}</span>
        </li>
        <li>
          <span>{{ $t('dapp.invitation.contractadres') }}</span>
          <span>{{ formateAddress(nft) }}</span>
        </li>
        <li v-if="!seller && tokenid == 'xx'">
          <span>{{ $t('nft.description.purchases') }}</span>
          <span>{{ number }}</span>
        </li>
        <li v-if="!seller && tokenid == 'xx'">
          <span>{{ $t('nft.description.surplus') }}</span>
          <span>{{ num }}</span>
        </li>
        <li v-if="tokenid != 'xx'">
          <span>TokenID</span>
          <span>{{ tokenid }}</span>
        </li>
        <li>
          <span>{{ $t('nft.description.network') }}</span>
          <span>BSC</span>
        </li>
      </ul>
      <div class="describe">{{ describe }}</div>
      <div class="hint">
        <h4>{{ $t('nft.description.rule') }}:</h4>
        <div>
          <p>1.{{ $t('nft.description.rule1') }}</p>
          <p>2.{{ $t('nft.description.rule2') }}</p>
          <p>3.{{ $t('nft.description.rule3') }}</p>
        </div>
      </div>
    </div>
    <van-dialog
      v-model:show="show"
      :showConfirmButton="false"
      class="popup"
      @closed="resetProject"
    >
      <DetailsPopup
        :catId="catId"
        :nftName="name"
        :price="price"
        :nftresidue="nftresidue"
        ref="projectRef"
        @closeDialog="closeDialog"
      ></DetailsPopup>
    </van-dialog>
    <van-dialog
      v-model:show="showSell"
      :showConfirmButton="false"
      cancel-button-color="popupsContent"
    >
      <div class="popups">
        <h3 class="title">{{ $t('nft.personal.personalHeld.importPrice') }}</h3>
        <div class="nftName">{{ name }}</div>
        <div class="ipt">
          <input type="number" v-model="nftPrice" />
          <span>KNT</span>
        </div>
        <div class="btn">
          <button @click="closeSell">{{ $t('nft.issuance.close') }}</button>
          <button @click="affirm">{{ $t('nft.issuance.confirm') }}</button>
        </div>
        <div class="close" @click="closeSell"></div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import ipfs from '@/utils/ipfs.js'
  import DetailsPopup from '@/views/Nft/NftDetail/DetailsPopup'
  import address from '@/web3/nft/address.json'
  import { Toast, Dialog } from 'vant'
  import { linkPurse } from '@/api/nft.js'
  import { useRouter, useRoute } from 'vue-router'
  import { onMounted, reactive, ref, toRefs } from 'vue'
  import { formatAmount } from '@/utils/format.js'
  import {
    buy,
    approve,
    getCatMetadata,
    cancelSales,
    buyNft,
    balanceOf,
    precision,
    catMetadata,
    approveTokenid,
    startSales,
  } from '@/api/nft.js'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'ProductDescription',
    setup() {
      let showSell = ref(false)
      let show = ref(false)
      const projectRef = ref(null)
      let closeDialog = () => {
        show.value = false
      }
      const { t } = useI18n()
      const router = useRouter()
      const route = useRoute()
      let dataObject = reactive({
        ...route.query,
        name: '',
        image: '',
        balance: 0,
        precisions: 18,
        nft: address.nft,
        describe: '',
        num: 0,
        number: 0,
      })
      let resetProject = () => {
        projectRef.value.reset()
      }
      let isLink = ref(false)
      let isMyNft = ref(false)
      if (route.query.seller == window.ethereum.selectedAddress)
        isMyNft.value = true
      let formateAddress = (address) => {
        return address && address.slice(0, 6) + '...' + address.slice(-6)
      }
      //链接钱包
      onMounted(() => {
        if (window.ethereum.selectedAddress) {
          isLink.value = true
        } else {
          isLink.value = false
        }
      })
      getData()
      async function getData() {
        if (dataObject.tokenid != 'xx') {
          let result = await getCatMetadata(dataObject.tokenid)
          dataObject.name = result.metadata.name
          dataObject.image = result.metadata.image
          dataObject.describe = result.metadata.describe
          dataObject.num = result.remainder
          dataObject.number = result.metadata.number
        } else {
          let result = await catMetadata(dataObject.catId)
          dataObject.name = result.metadata.name
          dataObject.image = result.metadata.image
          dataObject.describe = result.metadata.describe
          dataObject.num = result.remainder
          dataObject.number = result.metadata.number
        }
        let balance = await balanceOf()
        dataObject.precisions = await precision()
        dataObject.balance = formatAmount(
          Number(balance),
          Number(dataObject.precisions)
        )
      }
      //#region
      let link = () => {
        linkPurse()
      }
      let buynft = async () => {
        let price = formatAmount(
          Number(route.query.price),
          Number(dataObject.precisions)
        )
        if (Number(dataObject.balance) < price)
          return Toast.fail(t('toast.balance'))
        Toast.loading({
          message: t('toast.authorization'),
          forbidClick: true,
          duration: 0,
          loadingType: 'spinner',
        })
        try {
          await approve()
          Toast.loading({
            message: t('toast.loading'),
            forbidClick: true,
            duration: 0,
            loadingType: 'spinner',
          })
          try {
            await buy(dataObject.id)
            Toast.success(t('toast.win'))
            router.push({ name: 'Nft' })
          } catch (error) {
            console.log(error)
            Toast.fail(t('toast.fail'))
          }
        } catch (error) {
          Toast.fail(t('toast.fail'))
        }
      }
      let errorImg = require('@/assets/error.jpg')
      let imgerror = (e) => {
        e.target.src = errorImg
      }
      let checkPrice = (price) => {
        if (route.query.identify == 'held') return price
        let p = Number(formatAmount(price, dataObject.precisions))
        if (p.toString().split('.')[1]?.length > 6) {
          return p.toFixed(6)
        }
        return p
      }
      let cancel = async () => {
        Toast.loading({
          message: t('toast.voiceCanceling'),
          forbidClick: true,
          duration: 0,
          loadingType: 'spinner',
        })
        try {
          await cancelSales(Number(route.query.id))
          Toast.success(t('toast.win'))
          router.push({ name: 'Personal' })
        } catch (error) {
          console.log(error)
          Toast.fail(t('toast.fail'))
        }
      }
      //#endregion

      //项目发行进来
      let confirmNft = async () => {
        show.value = true
        return
      }
      //从自己拥有进来
      //出售
      let confirmMy = async () => {
        showSell.value = true
      }
      let nftPrice = ref(null)
      let affirm = async () => {
        if (
          nftPrice.value.toString().split('.')[1] &&
          nftPrice.value.toString().split('.')[1].length > 6
        ) {
          return Toast.fail(t('toast.decimalFraction'))
        }
        if (nftPrice.value <= 0) return Toast.fail(t('toast.winPrice'))
        Toast.loading({
          message: '正在授权出售',
          forbidClick: true,
          duration: 0,
          loadingType: 'spinner',
        })
        try {
          await approveTokenid(route.query.tokenid)
          Toast.loading({
            message: t('toast.loading2'),
            forbidClick: true,
            duration: 0,
            loadingType: 'spinner',
          })
          try {
            let precisions = await precision()
            await startSales(
              route.query.tokenid,
              formatAmount(Number(nftPrice.value), -Number(precisions))
            )
            Toast.success(t('toast.win'))
            showSell.value = false
            nftPrice.value = null
            router.push({ name: 'Personal' })
          } catch (error) {
            Toast.fail(t('toast.fail'))
          }
        } catch (error) {
          console.log(error)
          Toast.fail(t('toast.fail'))
        }
      }
      //关闭弹窗
      let closeSell = () => {
        showSell.value = false
        nftPrice.value = null
      }
      return {
        link,
        isLink,
        buynft,
        ...toRefs(dataObject),
        formateAddress,
        errorImg,
        imgerror,
        checkPrice,
        isMyNft,
        cancel,
        confirmNft,
        route,
        confirmMy,
        show,
        closeDialog,
        resetProject,
        projectRef,
        affirm,
        nftPrice,
        closeSell,
        showSell,
        ipfs,
      }
    },
    components: {
      DetailsPopup,
      [Dialog.Component.name]: Dialog.Component,
    },
  }
</script>

<style lang="scss" scoped>
  .productDescription {
    width: 100%;
    color: #fff;
    padding: 0 0.3846rem 1.5385rem;
    overflow: hidden;
    .content {
      padding: 0 0.5128rem 0.7179rem;
      overflow: hidden;
      margin-top: 1.7949rem;
      width: 100%;
      border-radius: 0.3077rem;
      background: rgba($color: #fff, $alpha: 0.15);
      .pdtImg {
        width: 8.2051rem;
        margin: 0.5128rem 0;

        img {
          border-radius: 0.2051rem;
          width: 100%;
        }
      }
      .buy {
        color: #fff;
        padding: 0.4103rem;
        border-radius: 0.3077rem;
        width: 100%;
        height: 4.1026rem;
        background: linear-gradient(to bottom, #202945, #161e34);
        h4 {
          font-size: 0.4615rem;
        }
        div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          span {
            color: #fff;
            font-size: 0.359rem;
            margin-top: 0.359rem;
          }
        }
        button {
          width: 7.1795rem;
          height: 1.0256rem;
          margin-top: 0.5128rem;
          border-radius: 0.3077rem;
          background: linear-gradient(to right, #ffd25e, #d28500);
          font-size: 0.4103rem;
        }
      }
      .source {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.3846rem;
        margin-top: 0.4103rem;
        border-radius: 0.3077rem;
        height: 4.1026rem;
        width: 100%;
        background: linear-gradient(to bottom, #202945, #161e34);
        li {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 0.359rem;
          }
          :nth-child(1) {
            opacity: 0.5;
          }
        }
      }
      .describe {
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
        padding: 0.3846rem;
        margin-top: 0.4103rem;
        border-radius: 0.3077rem;
        width: 100%;
        line-height: 0.641rem;
        background: linear-gradient(to bottom, #202945, #161e34);
        font-size: 0.359rem;
      }
      .hint {
        width: 100%;
        margin-top: 0.8205rem;
        opacity: 0.5;
        p {
          margin: 0.1282rem 0;
        }
      }
    }
  }
  .popup {
    height: 8.2051rem;
    background: linear-gradient(to bottom, #202945, #161e34);
  }
  .popups {
    height: 7.1795rem;
    background: linear-gradient(to top, #202945, #161e34);
    color: #fff;
    padding: 0.5128rem;
    .title {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.4615rem;
      font-weight: 700;
    }
    .nftName {
      text-align: center;
      margin-top: 0.8205rem;
      font-size: 0.5128rem;
    }
    .ipt {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5128rem;
      height: 1.2821rem;
      font-size: 0.5128rem;
      border: 0.0256rem solid #899ac9;
      border-radius: 0.2051rem;
      padding-left: 0.1282rem;
      input {
        text-align: center;
        flex: 3;
        width: 70%;
        border: 0;
        outline: none;
        background-color: transparent;
      }
      span {
        flex: 1;
      }
    }
    .close {
      width: 0.4103rem;
      height: 0.4103rem;
      position: absolute;
      right: 0.3077rem;
      top: 0.3077rem;
      background: url('../Personal/PersonalHeld/images/close@2x.png') center
        no-repeat;
      background-size: 0.4103rem;
    }
    .btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0.8205rem;
      width: 100%;
      button {
        height: 1.0256rem;
        width: 2.8718rem;
        border-radius: 0.5128rem;
        background-color: #313748;
        color: #fff;
        font-size: 0.4103rem;
      }
      :nth-child(2) {
        background: linear-gradient(to right, #ffd25e, #d28500);
      }
    }
  }
  .toast {
    text-align: center;
    opacity: 0.5;
    color: #fff;
  }
</style>
